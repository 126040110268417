<template>
  <!-- withdraw -->
  <div class="deposit-wrap">
    <van-nav-bar :title="title" left-arrow @click-left="onBack" :border="false" v-if="isHome&&!popupShow">
      <template #left>
        <img src="@/assets/images/system/left-arrow.svg"/>
      </template>
    </van-nav-bar>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
  name: 'Profile',
  components: {},
  computed: {
    title () {
      const title = this.$route.meta.title
      return title ? this.$t(`profile.nav.${title}`) : ''
    },
    isHome () {
      return this.$route.fullPath !== '/profile'
    }
  },
  watch: {
    '$route.meta' (n, o) {
      console.log(n, o)
    }
  },
  data () {
    return {
      popupShow: false
    }
  },
  mounted () {
    this.$EventBus.$on('changePopupShow', (bool) => {
      this.popupShow = bool
    })
  },
  methods: {
    onBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less">
.deposit-wrap {
  background-color: var(--background-color-4);
}
</style>
